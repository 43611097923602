import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError
} from '@reduxjs/toolkit/query/react';
import { environment } from 'config/environment';
import { getCookie } from 'react-use-cookie';

const baseQuery = fetchBaseQuery({
  //@ts-ignore
  baseUrl: environment.BASE_API_URL,
  prepareHeaders: async (headers, { getState }) => {
    const state = getState();
    const authToken = getCookie(environment.ACCESS_TOKEN_COOKIE);
    console.log({
      authToken
    });
    headers.set('Authorization', `Token ${authToken}`);

    return headers;
  }
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    // await removeData('accessToken')
    // api.dispatch(authenticationActions.resetToken());
    console.log({
      result
    });
  }

  return result;
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Post', 'Rating', 'Job'],
  endpoints: (builder) => ({
    getChatChannelIds: builder.mutation({
      query: () => ({
        url: `/api/v1/users/chat/channel-ids`,
        method: 'GET'
      })
    }),
    getApplicantsProfile: builder.query({
      query: (id = null) => {
        // debugger;

        return !id
          ? null
          : {
              url: '/api/v1/profile/' + id + '/',
              method: 'GET'
            };
      }
    }),
    viewApplicantsProfile: builder.mutation({
      query: (id = null) => {
        // debugger;

        return !id
          ? null
          : {
              url: '/api/v1/profile/' + id + '/',
              method: 'GET'
            };
      }
    }),
    getUserProfile: builder.query({
      query: () => ({
        url: '/api/v1/profile/',
        method: 'GET'
      })
    }),
    getAllJobs: builder.query({
      query: (params) => {
        const { limit, offset, search, city, state } = params;
        return {
          url: `/api/v1/all-jobs/`,
          method: 'GET',
          params: { limit, offset, search, city, state }
        };
      }
    }),
    viewAllJobs: builder.mutation({
      query: (params) => {
        const { limit, offset, title, city, state, is_paid } = params;
        const filterParams = (obj) => {
          const filtered = {};
          Object.keys(obj).forEach((key) => {
            const value = obj[key];
            if (
              value !== null &&
              value !== undefined &&
              value !== '' &&
              !(typeof value === 'object' && Object.keys(value).length === 0)
            ) {
              filtered[key] = value;
            }
          });
          return filtered;
        };

        const validParams = filterParams({ limit, offset, title, city, state, is_paid });

        return {
          url: `/api/v1/all-jobs/`,
          method: 'GET',
          params: validParams
        };
      }
    }),
    getDetailJobs: builder.query({
      query: (params) => {
        const { id, cancelCall } = params;
        return cancelCall === true
          ? null
          : {
              url: `/api/v1/all-jobs/${id}/`,
              method: 'GET'
            };
      }
    }),
    getDetailSpecificJobs: builder.query({
      query: (params) => {
        const { id, cancelCall } = params;
        return cancelCall === true
          ? null
          : {
              url: `/api/v1/jobs/${id}/`,
              method: 'GET'
            };
      }
    }),
    getDetailSpecificProjects: builder.query({
      query: (params) => {
        const { id, cancelCall } = params;
        return cancelCall === true
          ? null
          : {
              url: `/api/v1/projects/${id}/`,
              method: 'GET'
            };
      }
    }),
    getDetailJobView: builder.mutation({
      query: (id) => ({
        url: `/api/v1/all-jobs/${id}/`,
        method: 'GET'
      })
    }),
    getAllProjects: builder.query({
      query: (params) => {
        const { limit, offset, is_paid } = params;
        return {
          url: `/api/v1/all-projects/`,
          method: 'GET',
          params: { limit, offset, is_paid }
        };
      },
      providesTags: ['Post']
    }),
    viewAllProjects: builder.mutation({
      query: (params) => {
        const {
          limit = 20,
          offset,
          search,
          city,
          state,
          is_paid,
          trade,
          latitude,
          longitude,
          radius
        } = params;
        return {
          url: `/api/v1/all-projects/`,
          method: 'GET',
          params: {
            limit,
            offset,
            search,
            city,
            state,
            is_paid,
            trade,
            latitude,
            longitude,
            radius
          }
        };
      },
      providesTags: ['Post']
    }),
    getMyProjects: builder.query({
      query: (params) => {
        const { limit, offset } = params;
        return {
          url: `/api/v1/projects/`,
          method: 'GET',
          params: { limit, offset }
        };
      }
    }),
    getDetailProjects: builder.query({
      query: (params) => {
        const { id, cancelCall } = params;
        return cancelCall === true
          ? null
          : {
              url: `/api/v1/all-projects/${id}/`,
              method: 'GET'
            };
      }
    }),
    getDetailPerProject: builder.query({
      query: (params) => {
        const { id, cancelCall } = params;
        return cancelCall === true
          ? null
          : {
              url: `/api/v1/projects/${id}/`,
              method: 'GET'
            };
      }
    }),
    postQuickApplyJob: builder.mutation({
      query: (data) => ({
        url: `/api/v1/jobs/apply/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Job']
    }),
    postQuickApplyProject: builder.mutation({
      query: (data) => ({
        url: `/api/v1/projects/apply/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Post']
    }),
    postFavouriteJob: builder.mutation({
      query: (data) => ({
        url: `/api/v1/favorite-job/`,
        method: 'POST',
        body: data
      })
    }),
    getMyFavoriteJobList: builder.query({
      query: () => {
        return {
          url: `/api/v1/favorite-job/`,
          method: 'GET',
          params: { limit: 100, offset: 0 }
        };
      },
      providesTags: ['Job']
    }),
    putProfileUpdate: builder.mutation({
      query: (data) => {
        const {
          id,
          description,
          proffesions,
          trade_affiliation,
          certificates,
          skillset,
          industy,
          fileLogo,
          fileProject,
          ...clearedData
        } = data;

        let formData = new FormData();
        Object.keys(clearedData).map((f) => {
          formData.append(f, clearedData[f]);
        });
        return {
          url: `/api/v1/profile/${data.id}/`,
          method: 'PUT',
          body: formData
        };
      }
    }),
    patchProfileUpdate: builder.mutation({
      query: (data) => {
        let formData = new FormData();
        Object.keys(data).map((f) => {
          if (f === 'language' && data[f].length) {
            data[f].map((e, index) => {
              formData.append(`${f}[${index}]`, e);
            });
          } else {
            formData.append(f, data[f]);
          }
        });
        return {
          url: `/api/v1/profile/${data.profileId}/`,
          method: 'PATCH',
          body: formData
        };
      }
    }),
    patchProfilewithOutFromUpdate: builder.mutation({
      query: (data) => {
        return {
          url: `/api/v1/profile/${data.profileId}/`,
          method: 'PUT',
          body: data.body
        };
      }
    }),
    postSubmitReport: builder.mutation({
      query: (data) => {
        return {
          url: `/api/v1/send_email/`,
          method: 'POST',
          body: data
        };
      }
    }),
    getLocationCityAndState: builder.mutation({
      query: (data) => {
        return {
          url: `/api/v1/geocode/`,
          method: 'POST',
          body: data
        };
      }
    }),
    getWorkiumCompanyList: builder.query({
      query: () => {
        return {
          url: `/api/v1/workium/company/list/`,
          method: 'GET'
        };
      }
    }),
    postCompanyCreate: builder.mutation({
      query: (data) => {
        const { id, ...clearedData } = data;

        let formData = new FormData();
        Object.keys(clearedData).map((f) => {
          formData.append(f, clearedData[f]);
        });
        return {
          url: `/api/v1/workium/company/create/`,
          method: 'POST',
          body: formData
        };
      }
    }),
    postRemoveApplicant: builder.mutation({
      query: (data) => {
        let formData = new FormData();
        Object.keys(data).map((f) => {
          formData.append(f, data[f]);
        });
        return {
          url: `/api/v1/remove-applicant/`,
          method: 'POST',
          body: formData
        };
      }
    }),
    postRemoveProjectApplicant: builder.mutation({
      query: (data) => {
        let formData = new FormData();
        Object.keys(data).map((f) => {
          formData.append(f, data[f]);
        });
        return {
          url: `/api/v1/remove-project-applicant/`,
          method: 'POST',
          body: formData
        };
      }
    }),
    postChangePassword: builder.mutation({
      query: (data) => ({
        url: `/rest-auth/password/change/`,
        method: 'POST',
        body: data
      })
    }),
    getJobCategory: builder.query({
      query: () => {
        return {
          url: `/api/v1/job-categories/`,
          method: 'GET'
        };
      }
    }),
    getManageJobPost: builder.query({
      query: () => {
        return {
          url: `/api/v1/jobs/`,
          method: 'GET'
        };
      }
    }),
    getViewManageJobPost: builder.query({
      query: ({ limit, offset }) => {
        return {
          url: `/api/v1/jobs/?limit=${limit}&offset=${offset}`,
          method: 'GET'
        };
      }
    }),

    postCreateJob: builder.mutation({
      query: (data) => {
        let formData = new FormData();
        Object.keys(data).map((f) => {
          formData.append(f, data[f]);
        });
        return {
          url: `/api/v1/jobs/`,
          method: 'POST',
          body: formData
        };
      }
    }),
    getMyCompany: builder.query({
      query: () => {
        return {
          url: `/api/v1/company/`,
          method: 'GET'
        };
      }
    }),
    postUserIndustry: builder.mutation({
      query: (data) => {
        return {
          url: `/api/v1/user-industry/`,
          method: 'POST',
          body: data
        };
      }
    }),
    postUserConstructionTrade: builder.mutation({
      query: (data) => {
        return {
          url: `/api/v1/user-construction-trade/`,
          method: 'POST',
          body: data
        };
      }
    }),
    postUserLaborAffiliation: builder.mutation({
      query: (data) => {
        return {
          url: `/api/v1/user-labor-affiliation/`,
          method: 'POST',
          body: data
        };
      }
    }),
    postAddLanguage: builder.mutation({
      query: (data) => {
        return {
          url: `/api/v1/profile/add-language/`,
          method: 'POST',
          body: data
        };
      }
    }),
    postUserMedia: builder.mutation({
      query: (data) => {
        let formData = new FormData();
        // Object.keys(data).map((f) => {
        //   formData.append(`image`, data[f]);
        // });
        Array.from(data)?.forEach((value) => {
          formData.append('image', value);
        });
        return {
          url: `/api/v1/user-media/`,
          method: 'POST',
          body: formData
        };
      }
    }),
    postMultiple: builder.mutation({
      query: (data) => {
        let formData = new FormData();
        Object.keys(data).map((f) => {
          formData.append('images', data[f]);
        });
        return {
          url: `/api/v1/user-media/upload_multiple/`,
          method: 'POST',
          body: formData
        };
      }
    }),
    postUserSkillSet: builder.mutation({
      query: (data) => {
        return {
          url: `/api/v1/user-skill-set/`,
          method: 'POST',
          body: data
        };
      }
    }),
    postUserEducation: builder.mutation({
      query: (data) => {
        return {
          url: `/api/v1/user-education/`,
          method: 'POST',
          body: data
        };
      }
    }),
    postUserProfession: builder.mutation({
      query: (data) => {
        return {
          url: `/api/v1/user-profession/`,
          method: 'POST',
          body: data
        };
      }
    }),
    postUserTradeLicense: builder.mutation({
      query: (data) => {
        return {
          url: `/api/v1/user-trade-license/`,
          method: 'POST',
          body: data
        };
      }
    }),
    getUserIndustry: builder.query({
      query: () => {
        return {
          url: `/api/v1/user-industry/`,
          method: 'GET'
        };
      }
    }),

    putUpdateCompany: builder.mutation({
      query: (data) => {
        let formData = new FormData();
        Object.keys(data).map((f) => {
          formData.append(f, data[f]);
        });
        return {
          url: `/api/v1/company/${data?.companyId}/`,
          method: 'PUT',
          body: formData
        };
      }
    }),

    postCreateCompany: builder.mutation({
      query: (data) => {
        let formData = new FormData();
        Object.keys(data).map((f) => {
          formData.append(f, data[f]);
        });
        return {
          url: `/api/v1/company/`,
          method: 'POST',
          body: formData
        };
      }
    }),

    deleteFavoriteJob: builder.mutation({
      query: (id) => {
        return {
          url: `/api/v1/favorite-job/${id}/`,
          method: 'DELETE'
        };
      }
    }),
    getLanguageView: builder.query({
      query: () => {
        return {
          url: `/api/v1/language/`,
          method: 'GET'
        };
      }
    }),
    getJobApplicants: builder.query({
      query: () => {
        return {
          url: `/api/v1/my-applications/`,
          method: 'GET'
        };
      }
    }),
    addFavoriteProject: builder.mutation({
      query: (data) => {
        return {
          url: `/api/v1/favourite-project/`,
          method: 'POST',
          body: data
        };
      }
    }),
    deleteFavoriteProject: builder.mutation({
      query: (id) => {
        return {
          url: `/api/v1/favourite-project/${id}/`,
          method: 'DELETE'
        };
      }
    }),
    getFavouriteProjects: builder.query({
      query: () => {
        return {
          url: `/api/v1/favourite-project/`,
          method: 'GET',
          params: { limit: 100, offset: 0 }
        };
      }
    }),
    // test to get all fav with mutation
    getFavouriteProjWithMutate: builder.mutation({
      query: () => {
        return {
          url: `/api/v1/favourite-project/`,
          method: 'GET',
          params: { limit: 100, offset: 0 }
        };
      }
    }),
    getProjectCategories: builder.query({
      query: () => {
        return {
          url: `/api/v1/project-category/`,
          method: 'GET'
        };
      }
    }),
    getUserContructionTrade: builder.query({
      query: () => {
        return {
          url: `/api/v1/user-construction-trade/`,
          method: 'GET'
        };
      }
    }),
    getProjectSubCategories: builder.query({
      query: () => {
        return {
          url: `/api/v1/project-subcategory/`,
          method: 'GET'
        };
      }
    }),
    getUserTradeLicences: builder.query({
      query: () => {
        return {
          url: `/api/v1/user-trade-license/`,
          method: 'GET'
        };
      }
    }),
    getPayments: builder.query({
      query: () => {
        return {
          url: `/api/v1/payments/`,
          method: 'GET'
        };
      }
    }),

    postCreateProject: builder.mutation({
      query: (data) => {
        let formData = new FormData();
        Object.keys(data).map((f) => {
          if (f === 'photos' || f === 'files') {
            data[f].map((d, idx) => {
              console.log({
                d,
                test: data[f][d]
              });
              formData.append(`${f}[${idx}]`, d.file);
              formData.append('image_objects', d.file);
            });
          } else {
            formData.append(f, data[f]);
          }
        });
        return {
          url: `/api/v1/projects/`,
          method: 'POST',
          body: formData
        };
      }
    }),
    postPaymentCharge: builder.mutation({
      query: (data) => {
        return {
          url: `/api/v1/charge-payment/`,
          method: 'POST',
          body: data
        };
      }
    }),
    postAddCard: builder.mutation({
      query: (data) => {
        // debugger;
        return {
          url: `/api/v1/add-card/`,
          method: 'POST',
          body: data
        };
      }
    }),
    getAllCards: builder.query({
      query: (params) => {
        const { id } = params;

        return {
          url: `/api/v1/get-all-cards/`,
          method: 'GET'
        };
      }
    }),
    deleteMyJob: builder.mutation({
      query: (id) => {
        return {
          url: `/api/v1/jobs/${id}/`,
          method: 'DELETE'
        };
      }
    }),
    patchMyJob: builder.mutation({
      query: (data) => {
        const id = data.id;
        delete data.id;
        let formData = new FormData();
        Object.keys(data).map((f) => {
          formData.append(f, data[f]);
        });
        return {
          url: `/api/v1/jobs/${id}/`,
          method: 'PATCH',
          body: formData
        };
      }
    }),
    patchMyProject: builder.mutation({
      query: (data) => {
        const id = data.id;
        delete data.id;
        let formData = new FormData();
        Object.keys(data).map((f) => {
          formData.append(f, data[f]);
        });
        return {
          url: `/api/v1/projects/${id}/`,
          method: 'PATCH',
          body: formData
        };
      }
    }),
    updateMyProject: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/api/v1/projects/${id}/`,
          method: 'PUT',
          body: data
        };
      }
    }),
    getSubscription: builder.query({
      query: () => {
        return {
          url: `/api/v1/subscription/`,
          method: 'GET'
        };
      }
    }),

    rating: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/api/v1/rating/?job_id=${id}/`,
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['Rating']
    }),

    patchRating: builder.mutation({
      query: ({ id, data }) => {
        return {
          // url: `/api/v1/rating/${id}/`,
          url: `/api/v1/rating/${id}/`,
          method: 'PATCH',
          body: data
        };
      },
      invalidatesTags: ['Rating']
    }),
    getAllRating: builder.query({
      query: (params) => {
        const { job_id, project_id } = params;
        return {
          url: `api/v1/rating/`,
          method: 'GET',
          params: { job_id, project_id }
        };
      },
      providesTags: ['Rating']
    })
  })
});

export const {
  useGetChatChannelIdsMutation,
  useGetUserProfileQuery,
  useGetAllJobsQuery,
  useGetAllProjectsQuery,
  useViewAllProjectsMutation,
  useGetDetailProjectsQuery,
  useGetDetailPerProjectQuery,
  useGetDetailJobsQuery,
  useGetDetailSpecificJobsQuery,
  useGetDetailSpecificProjectsQuery,
  useGetAllCardsQuery,
  usePostQuickApplyJobMutation,
  usePostQuickApplyProjectMutation,
  usePutProfileUpdateMutation,
  usePostChangePasswordMutation,
  usePostCreateJobMutation,
  useGetJobCategoryQuery,
  useGetJobApplicantsQuery,
  useGetFavouriteProjectsQuery,
  useGetFavouriteProjWithMutateMutation,
  usePostSubmitReportMutation,
  useGetLocationCityAndStateMutation,
  useGetProjectCategoriesQuery,
  usePostCreateProjectMutation,
  useGetMyProjectsQuery,
  usePostFavouriteJobMutation,
  useGetMyFavoriteJobListQuery,
  useDeleteFavoriteJobMutation,
  useGetManageJobPostQuery,
  useAddFavoriteProjectMutation,
  useDeleteFavoriteProjectMutation,
  useGetProjectSubCategoriesQuery,
  useGetApplicantsProfileQuery,
  useGetDetailJobViewMutation,
  useViewApplicantsProfileMutation,
  useGetUserTradeLicencesQuery,
  usePostCreateCompanyMutation,
  useGetMyCompanyQuery,
  useGetPaymentsQuery,
  useGetUserIndustryQuery,
  useGetUserContructionTradeQuery,
  usePatchProfilewithOutFromUpdateMutation,
  usePostCompanyCreateMutation,
  useGetWorkiumCompanyListQuery,
  usePostUserIndustryMutation,
  usePostUserSkillSetMutation,
  usePostUserEducationMutation,
  usePostUserProfessionMutation,
  usePostUserTradeLicenseMutation,
  usePatchProfileUpdateMutation,
  usePostUserMediaMutation,
  usePostMultipleMutation,
  useGetLanguageViewQuery,
  useGetSubscriptionQuery,
  usePostPaymentChargeMutation,
  usePostAddCardMutation,
  usePostRemoveApplicantMutation,
  usePostRemoveProjectApplicantMutation,
  useGetViewManageJobPostQuery,
  useViewAllJobsMutation,
  useDeleteMyJobMutation,
  usePatchMyJobMutation,
  usePatchMyProjectMutation,
  useUpdateMyProjectMutation,
  usePutUpdateCompanyMutation,
  usePostAddLanguageMutation,
  usePostUserLaborAffiliationMutation,
  usePostUserConstructionTradeMutation,
  useRatingMutation,
  useGetAllRatingQuery,
  usePatchRatingMutation
} = api;
